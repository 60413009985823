@font-face {
    font-family: GothamPro;
    src: local(GothamProMed), url(../assets/fonts/GothaProMed.otf) format("truetype");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: GothamProRegular;
    src: local(GothamProReg), url(../assets/fonts/GothaProReg.otf) format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}


::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1.25px 1px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 1.25px 1px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb {
    background: rgb(41, 118, 250);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(54%, rgba(41, 118, 250, 1)), to(rgba(68, 169, 252, 1)));
    border-radius: 4px;
}